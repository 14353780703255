@import url('./assets/css/fonts.css');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header,
.App-footer {
  /*  background-color: #e9e9e9; 
   min-height: 100vh; 
   flex-direction: column; */
  border: 2px solid #f3f5f8;
  background: linear-gradient(45deg, #a1b3cd, transparent);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
  border-radius: 10px;
  padding: 0 10px;
}

.App-footer {
  /* position: fixed; */
  bottom: 0;
  width: 100%;
  margin-top: 20px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.hide {
  display: none;
}

.rdt_TableHead .rdt_TableCol {
  font-weight: 800 !important;
  background-color: lightgrey;
  padding: 10px 0px;
  justify-content: center;
  font-size: 14px;
}

.rdt_TableRow .rdt_TableCell{
  font-size: 14px;
}

.rdt_TableHead .rdt_TableCol:not(:first-child):not(:last-child)>div {
  justify-content: flex-start;
}

.rdt_TableRow .rdt_TableCell{
  padding: 10px 0px;
  padding-right:10px;
}

.rdt_TableRow .rdt_TableCell:first-child>div {
  text-align: center;
}

.heading-section {
  color: black !important;
  text-shadow: 0px 1px 5px white;
  font-weight: 800;
}

.workoutDT {
  scrollbar-width: auto;
  scrollbar-color: #bdbbbb #ffffff;
}

/* Chrome, Edge, and Safari */
.workoutDT::-webkit-scrollbar {
  width: 10px;
  height: 15px;
}

.workoutDT::-webkit-scrollbar-track {
  background: #ffffff;
}

.workoutDT::-webkit-scrollbar-thumb {
  background-color: #bdbbbb;
  border-radius: 12px;
  border: 3px solid #ffffff;
}

.workoutDT,
.exerciseDT,
.muscleDT,
.categoryDT,
.typeDT{
  border: 2px solid;
  border-radius: 10px !important;
}


.workout-dt .rdt_TableCell:nth-child(2),
.typeDT .rdt_TableCell:nth-child(2),
.categoryDT .rdt_TableCell:nth-child(2),
.muscleDT .rdt_TableCell:nth-child(2),
.exerciseDT .rdt_TableCell:nth-child(2){
  font-weight: 800;
}

.typeDT .rdt_TableCell:not(:first-child):not(:last-child),
.categoryDT .rdt_TableCell:not(:first-child):not(:last-child),
.muscleDT .rdt_TableCell:not(:first-child):not(:last-child),
.exercise-dt .rdt_TableCell:not(:first-child):not(:last-child){
  text-align: left;
}

.typeDT .rdt_TableCell:last-child,
.categoryDT .rdt_TableCell:last-child,
.muscleDT .rdt_TableCell:last-child,
.exercise-dt .rdt_TableCell:last-child{
  text-align: center;
  justify-content: center;
}