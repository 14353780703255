body {
  margin: 0;
  font-family: 'Smitch Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.nav-pills .nav-link {
  text-align: start;
}

/* .nav-pills .nav-link {
  border: 1px solid #0d6efd;    
}

.nav-pills .nav-link:not(:nth-last-child()){
  border-bottom: none;
}
 */
.nav-tabs .nav-link.active {
  background-color: #0d6efd;
  color: #fff;
}


.workout-tabs .nav-link {
  color: #6a6969;
}

.workout-tabs .nav-link.active {
  background-color: #e1e1e1;
  color: #000;
}


.list-group-item {
  text-align: start !important;
}

.form-check-inline {
  text-align: left;
}

.table th,
.table td {
  text-align: left;
  vertical-align: middle;
}

/* .table-striped>tbody>tr{
  vertical-align: middle;
} */

.workout-creation .card-body,
.Mapping-Entities .card-body .list-group{
  min-height: 250px;
  max-height: 250px;
  overflow-y: auto;
  padding-top: 0.25rem!important;
  padding-bottom: 0.25rem!important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.card-body {
  scrollbar-width: auto;
  scrollbar-color: #8f54a0 #ffffff;
}

/* Chrome, Edge, and Safari */
.Instructions-list::-webkit-scrollbar,
.Exercise-Instructions::-webkit-scrollbar,
.card-body::-webkit-scrollbar,
.list-group::-webkit-scrollbar {
  width: 10px;
}

.Instructions-list::-webkit-scrollbar-track, 
.Exercise-Instructions::-webkit-scrollbar-track, 
.card-body::-webkit-scrollbar-track, 
.list-group::-webkit-scrollbar-track {
  background: #ffffff;
}

.Instructions-list::-webkit-scrollbar-thumb,
.Exercise-Instructions::-webkit-scrollbar-thumb,
.card-body::-webkit-scrollbar-thumb,
.list-group::-webkit-scrollbar-thumb {
  background-color: #8f54a0;
  border-radius: 12px;
  border: 3px solid #ffffff;
}

.card>.list-group {
  border-top: 0px;
}

.accordion-button {
  background-color: #cfe2ff !important;
}

.hide-checkbox {
  display: none;
}
.hide{
  display: none;
}

.WorkoutOutput .card-body {
  min-height: 250px;
  max-height: 250px;
  overflow-y: auto;
}

.width-100 {
  width: 100%;
}

.delete-exercise-mapping{
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-check{
  min-height: auto !important;
}
.fitness-login{
  background-image: url(assets/img/login-bg.jpg);
  background-position: center;
  background-size: cover;
  background-attachment:fixed;
}

.login-section{
  background-color: #ffffffb0;
  border-radius: 10px;
  padding: 1rem;
  margin-left: 10%;
}

.fitness-logo{
  max-width: 150px;
  margin-bottom: 10px;
}
.ai-coach-icon{
  width: 35px;
  margin-right:10px;
}

.exercise-filtered-add:before {
  content: "\002B";  
  background: green; 
}

.exercise-filtered-remove:before{
  content: "\002D";  
  background: red; 
}

.exercise-filtered-add:before,
.exercise-filtered-remove:before {
  color: white;  
  font-size: 20px;
  height: 20px;
  width: 20px;
  line-height: 19px;
  text-align: center;
  display: block;
  border-radius: 50%;
  margin-right: 5px;
}
.exercise-filtered-remove .form-check-input[type=checkbox],
.exercise-filtered-add .form-check-input[type=checkbox]{
  display: none;  
}

.exercise-filtered-remove,
.exercise-filtered-add {
  padding-left: 0em;
  display: flex;
  justify-content: flex-start;
}

.Checkbox-View .form-check-input:checked[type=checkbox]~.form-check-label{
  color:green;
  opacity: 1;
}

.Checkbox-View .form-check-input:checked {
  background-color: green;
  border-color: green;
}

.Checkbox-View .form-check-input:disabled~.form-check-label, 
.Checkbox-View .form-check-input[disabled]~.form-check-label{
  opacity: .8;
}

.exercise-description {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.exercise-image img {
  width: 100%;
  border-radius: 5px;
}

.filter-exercise .react-select{
  text-align: left;
  
}

.text-capitalize{
  text-transform: capitalize;
}

.preloader-section{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    background-color: #ada4a463;    
    z-index: 9999;
}

.preloader-section img{
  max-width: 500px;
}

.exercise-preloader-section{
  width: 100%;
  text-align: center;
}

.exercise-preloader-section img{
  max-width: 200px;
}

.form-switch .form-check-input {
  width: 50px;
  height: 25px;
}

.ai-coach-tabs li:nth-child(3) {
  display: none;
}
/* 
.ai-coach-tabs li:nth-child(2) {
  display: none;
} */

.Exercise-Instructions{
  min-height: 275px !important;
  resize: none;
}

.preview-instructions{
  border: 1px solid #dee2e6;
  border-radius: 10px;
  padding: 10px 0px;
}

.Instructions-list{
  padding-left: 0rem;
  max-height: 250px;
  min-height: 250px;
  overflow-y: scroll;
  padding: 0px 10px 0px;
  margin-bottom:0px;
}

.Instructions-list li{
  color:#000000;
  list-style:none;
  position: relative;
  line-height: 2;
  padding-left:1.5rem;
}
.Instructions-list li:before {
  position: absolute;
  left: 0;
  color:#000000;
  content:"\2743"; 
}

button:disabled {
  pointer-events: auto !important;
  cursor: not-allowed;
}

.workout-exercise-mapping .accordion-button{
  padding: 10px;
}

.exercise-sets-table :is(td, th):not(:nth-child(2)) {
  text-align: center;
}

.exercise-video {
  width: 100%;
  border: 2px solid;
}
.overview-exercise .modal-content{
  background: linear-gradient(45deg, #d2cdfb, #fffbfb);
}
.exercises-list{
  cursor: pointer;
  border: 2px solid rgb(203 203 203);
  background: linear-gradient(45deg, #e8ebef, transparent);
}
.exercises-list .card-header{
  border-bottom:none;
}
/* Activity Template */

.activity-template .card {
  border-radius: 20px;
  background: #1b233d;
  padding: 5px;
  overflow: hidden;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 20px 0px;
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: background-color 0.5s ease;
}

.activity-template  .card-img-top{
  border-radius: 10px;

}

.activity-template .card {
  color: #b6d9fb;
}

.activity-template .card:hover {
  transform: scale(1.01);
  color: #000;
  background: #fff;
  border:2px solid #1b233d;
}

.activity-template .card .card-title{
  text-align: center;
}

.activity-template .card .card-text {
  max-height: 3em; /* Adjust this height as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.activity-template .card .muscle-list{
  background-color: #ffffff33;
  border: solid transparent;
  border-radius: 7px;
  margin-bottom: 10px;
}

.activity-template .card .muscle-list .badge {
  font-weight: normal;
}
.activity-template .card .muscle-section {
  max-height: 140px;
  min-height: 140px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.activity-template .card .muscle-section {
  scrollbar-width: auto;
  scrollbar-color: #8f54a0 #ffffff;
}

/* Chrome, Edge, and Safari */
.activity-template .card .muscle-section::-webkit-scrollbar {
  width: 10px;
}

.activity-template .card .muscle-section::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
}

.activity-template .card .muscle-section::-webkit-scrollbar-thumb {
  background-color: #8f54a0;
  border-radius: 12px;
  border: 3px solid #ffffff;
}

/* Add this to your CSS */
.block-content {
  opacity: 0;
  max-height: 0;
 /*  overflow: hidden; */
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

.expandable-block.expanded .block-content {
  opacity: 1;
  max-height: 1000px;
}

.activity-filter-section{
  border: 2px solid #87aef5;
  padding: 10px;
  border-radius: 10px;
  background: #b6c4f770;
}

.activity-table{
  position: relative;
  width: 100%;
  overflow: auto; 
  max-height: 600px;
}

.activity-table {
  scrollbar-width: auto;
  scrollbar-color: #8f54a0 #ffffff;
}

/* Chrome, Edge, and Safari */
.activity-table::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

.activity-table::-webkit-scrollbar-track {
  background: #ffffff;
}

.activity-table::-webkit-scrollbar-thumb {
  background-color: #8f54a0;
  border-radius: 12px;
  border: 3px solid #ffffff;
}

/* .row-stage-1 td{
  background-color: #FFCCCB;
  color:red
}
.row-stage-2 td{
  background-color: #ffdd91e8;
  color:orangered
}
.row-stage-3 td{
  background-color: #b6ff91e8;
  color:green
} */


.activity-table .table>:not(caption)>*>*{
  box-shadow: none;
}

.activity-table th{
  background-color: #edebeb;
}

.activity-table {
  width: 100%;
  overflow-x: auto;
  border-radius: 10px;
  z-index: 0;
}

.activity-table .table {
  width: 100%;
  border-collapse: collapse;
}

.activity-table .table th, .activity-table .table td {
  border: 1px solid #ddd;
}


.header-row {
  position: sticky;
  top: 0;
  background-color: #f2f2f2;
  z-index: 1;
}

/*  */

.eq-count-table{
  display: table;
  border-collapse: collapse;
  width: 100%;
  border-radius: 10px;
}

.eq-count-table span {
  display: table-cell;
  padding: 0px;
  border: 1px solid rgb(214, 213, 213);
  
}

.eq-count-table span:first-child{
  background-color: #FFCCCB;
}
.eq-count-table span:nth-child(2){
  background-color: #ffdd91e8;
}
.eq-count-table span:last-child{
  background-color: #b6ff91e8;
}


/*  */
.rdt_Table .rdt_TableBody div>div>div{
  width: 100%;
}

.fit-content-cell {
  width: fit-content;
  min-width: 100px;
}

.rdt_TableCol>div>div{
  overflow: unset;
    white-space: normal;
    text-overflow: ellipsis;
    padding: 5px 0px;
}

/* .rdt_TableCol,
.rdt_TableCell{
  
} */

.rdt_TableCell>div{
  white-space: normal !important;
}